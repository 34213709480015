
import { defineComponent } from 'vue'

import UserManagementList from '@/components/user-management/user-management-list.vue'

export default defineComponent({
	components: {
		UserManagementList,
	},
	setup() {
		return {}
	},
})
