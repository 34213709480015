
import { defineComponent, onBeforeMount, ref } from 'vue'

import { fetchUserManagementUsers } from '@/service/users.http'
import { fetchTenantList } from '@/service/tenant.http'
import { IUserQueryParams } from '@/models/user/user'
import { UserStatus } from '@myndshft/types-mc-api'
import { ITenantDetails } from '../../../server/api/models/fe/tenant'
import { IUserPage } from '../../../server/api/models/fe/user'
import { userStatusOptions } from '@/models/user/user'
import { getV2Route } from '@/service/shared.http'

import { MyndshftPanel } from '@myndshft/panel'
import SearchSelect from '@/components/search/search-select.vue'
import AssociatedClientsCell from './associated-clients-cell.vue'
import UserStatusPill from '@/components/shared/user/user-status.vue'
import V2Cta from '@/components/shared/v2-cta.vue'

interface IVSelectItem {
	text: string
	value: string
}

export default defineComponent({
	name: 'user-management-list',
	components: {
		MyndshftPanel,
		SearchSelect,
		AssociatedClientsCell,
		UserStatusPill,
		V2Cta,
	},
	props: {
		canFilterUsers: { type: Boolean, default: false },
		tableTitle: { type: String, default: '' },
		tenantId: { type: String, default: '' },
	},
	setup(props) {
		// Search
		const searchQuery = ref<string | null>(null)
		const searchOptions = [
			{ text: 'User Name', value: 'name' },
			{ text: 'Email', value: 'email' },
		]
		const searchType = ref(searchOptions[0].value)
		// Filter
		const tenants = ref<IVSelectItem[]>([])
		const selectedTenant = ref<string | null>(props.tenantId || null)
		const selectedStatuses = ref<string[]>(
			props.canFilterUsers
				? [UserStatus.ACTIVE, UserStatus.INACTIVE, UserStatus.UNVERIFIED]
				: []
		)
		const statusItems = userStatusOptions()
		// User Table
		const userPages = ref<IUserPage[]>([{} as IUserPage])
		const currentPageIndex = ref(0)
		const disablePrevious = ref(true)
		const disableNext = ref(true)
		const isLoading = ref(false)

		const tableHeaders = [
			{
				value: 'name',
				text: 'Name',
				sortable: false,
			},
			{
				value: 'email',
				text: 'E-Mail',
				sortable: false,
			},
			{
				value: 'tenant',
				text: 'Tenant',
				sortable: false,
			},
			{
				value: 'clients',
				text: 'Associated Clients',
				width: '350px',
				sortable: false,
			},
			{
				value: 'status',
				text: 'Status',
				width: '130px',
				sortable: false,
			},
		]

		// Search and Filter Methods
		const mapTenantsToAutocomplete = (tenantsList: ITenantDetails[]) => {
			const mappedList = tenantsList.map(tenant => {
				return {
					text: tenant.name,
					value: tenant.id,
				}
			})
			const sortedList = mappedList.sort((a: IVSelectItem, b: IVSelectItem) =>
				a.text.localeCompare(b.text)
			)
			return sortedList
		}

		const applyFilter = () => {
			isLoading.value = true
			const queryParams: IUserQueryParams = {
				pageToken: '',
				tenantId: selectedTenant.value,
				statuses: selectedStatuses.value.length ? selectedStatuses.value : null,
				[searchType.value]: searchQuery.value,
			}
			fetchUserManagementUsers(queryParams)
				.then((userPage: IUserPage) => {
					userPages.value = [userPage]
					currentPageIndex.value = 0
					setNavButtonState()
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const clearAll = () => {
			selectedTenant.value = null
			selectedStatuses.value = [
				UserStatus.ACTIVE,
				UserStatus.INACTIVE,
				UserStatus.UNVERIFIED,
			]
			searchQuery.value = null
			applyFilter()
		}

		// User Table Methods
		const setNavButtonState = () => {
			if (currentPageIndex.value === 0) {
				disablePrevious.value = true
			} else {
				disablePrevious.value = false
			}
			disableNext.value =
				!userPages.value[currentPageIndex.value]?.nextPageToken
		}

		const handleClickPrevious = () => {
			currentPageIndex.value = currentPageIndex.value - 1
			setNavButtonState()
		}

		const handleClickNext = () => {
			if (currentPageIndex.value < userPages.value.length - 1) {
				currentPageIndex.value = currentPageIndex.value + 1
				setNavButtonState()
			} else {
				isLoading.value = true
				const queryParams: IUserQueryParams = {
					pageToken: userPages.value[currentPageIndex.value]?.nextPageToken,
					tenantId: selectedTenant.value,
					statuses: selectedStatuses.value,
					[searchType.value]: searchQuery.value,
				}
				fetchUserManagementUsers(queryParams)
					.then((userPage: IUserPage) => {
						userPages.value.push(userPage)
						currentPageIndex.value = currentPageIndex.value + 1
						setNavButtonState()
					})
					.finally(() => {
						isLoading.value = false
					})
			}
		}

		// Shared
		onBeforeMount(() => {
			isLoading.value = true
			Promise.all([
				fetchTenantList(),
				fetchUserManagementUsers({
					pageToken: '',
					statuses: selectedStatuses.value,
					tenantId: selectedTenant.value,
				}),
			])
				.then(([tenantsResponse, userPage]) => {
					tenants.value = mapTenantsToAutocomplete(tenantsResponse.tenants)
					userPages.value = [userPage]
					setNavButtonState()
				})
				.finally(() => {
					isLoading.value = false
				})
		})

		return {
			searchQuery,
			searchType,
			searchOptions,
			tenants,
			selectedTenant,
			selectedStatuses,
			statusItems,
			UserStatus,
			userPages,
			currentPageIndex,
			isLoading,
			disablePrevious,
			disableNext,
			tableHeaders,
			applyFilter,
			clearAll,
			handleClickNext,
			handleClickPrevious,
			getV2Route,
		}
	},
})
